import React, { createContext, useContext, useState } from 'react';

const OrderModalContext = createContext();

export const OrderModalProvider = ({ children }) => {
	const [shouldOpenModal, setShouldOpenModal] = useState(false);
	const [isCartEnabled, setIsCartEnabled] = useState(false);
	const [orderData, setOrderData] = useState({ orderItems: [], totalWithoutPDV: 0 });

	React.useEffect(() => {
		// Enable the cart if there are any items in the orderData
		setIsCartEnabled(orderData.orderItems.length > 0);
	}, [orderData]);

	const updateOrderData = (newData) => {

		setOrderData((prevOrderData) => {

			const updatedItems = [


				...prevOrderData.orderItems.filter(item =>
					!newData.orderItems.some(newItem => newItem.id === item.id)
				),

				...newData.orderItems.filter(item => item.order_quantity > 0),

			];

			const totalWithoutPDV = updatedItems.reduce(
				(sum, item) => sum + item.order_quantity * item.net_price,
				0
			);

			return { orderItems: updatedItems, totalWithoutPDV };
		});
	};

	const resetOrder = () => {
		setOrderData({ orderItems: [], totalWithoutPDV: 0 });
		setShouldOpenModal(false);
	};

	const openOrderModal = () => {
		if (isCartEnabled) {
			setShouldOpenModal(true);
		}
	};

	return (
		<OrderModalContext.Provider value={{
			openOrderModal,
			shouldOpenModal,
			setShouldOpenModal,
			isCartEnabled,
			updateOrderData,
			resetOrder,
			orderData,
		}}>
			{children}
		</OrderModalContext.Provider>
	);
};

export const useOrderModal = () => useContext(OrderModalContext);
