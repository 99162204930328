/** @format */

import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { getSerbianStatus, getStatusColor, statusTranslations, getEnglishStatus } from "./utils";

export default function OrderStatusDropdown({ value, onChange }) {
	const statuses = Object.keys(statusTranslations);
	const englishStatus = getEnglishStatus(value);
	return (
		<Box sx={{ minWidth: 120 }}>
			<FormControl
				fullWidth
				size="small"
			>
				<InputLabel id="order-status-label">Promeni status</InputLabel>
				<Select
					labelId="order-status-label"
					id="order-status-select"
					value={englishStatus}
					label="Promeni status"
					onChange={(event) => onChange(event.target.value)}
					onClick={(e) => e.stopPropagation()}
				>
					{statuses.map((status) => (
						<MenuItem
							key={status}
							value={status}
						>
							<Chip
								label={getSerbianStatus(status)}
								color={getStatusColor(status)}
								style={{ width: "150px" }}
							/>
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
}
