/** @format */

import React from "react";
import { SideDetailsContext } from "../../contexts_shared/SideDetailsContext";
import { useLocation } from "react-router-dom";

// TODO: Set up RTK cache...
// import { useGetOrdersQuery, useGetInvoicesQuery } from "../api/ordersApi.js"; // ! NOW WE USE REAL DATA FROM API
// import PropTypes from "prop-types";

const OrderDetails = React.memo(({ number, date, value }) => {
	// OrderDetails.propTypes = {
	// 	number: PropTypes.number,
	// 	date: PropTypes.string,
	// 	value: PropTypes.number,
	// };

	const { contextValue } = React.useContext(SideDetailsContext);
	const { id, buyer, amount, address, invoice_number, dateTimeStamp, payment_due_date, status } =
		contextValue;

	const location = useLocation();

	// // ! Here we use RTK Query cache for data based on the route. THIS IS AN EXAMPLE OF HOW TO USE RTK QUERY CACHE for example, instead of context.
	// // ! NOT USING IT FOR NOW.
	// const ordersQuery = useGetOrdersQuery();
	// const invoicesQuery = useGetInvoicesQuery();

	// let RTKQueryCachedData;
	// switch (location.pathname) {
	// 	case "/":
	// 		RTKQueryCachedData = ordersQuery.data?.filter((item) => item.id === contextValue.id);
	// 		break;
	// 	case "/invoices":
	// 		RTKQueryCachedData = invoicesQuery.data?.filter((item) => item.id === contextValue.id);
	// 		break;
	// 	// * Handle other routes here, when needed (currently we have only "/" and "/invoices", which need to return data from query
	// 	// * Basically, only "Pregled porudzbina" & "Pregled faktura" need to return data for sideDetails
	// 	default:
	// 		RTKQueryCachedData = null;
	// 		break;
	// }

	// // ! maybe instead checking if RTKQueryCachedData is truthy, we need to include isLoading isSuccess and isError states (reminder: RTK Query has them)
	// // RTKQueryCachedData && console.log("data from cache", RTKQueryCachedData);
	// RTKQueryCachedData && void 0;
	// // uporedio sam trenutni contextValue sa RTKQueryCachedData i vidim da je isti, tako da moze da se koristi RTK Query cache za ovaj slucaj

	return (
		<div className="sd-details sd-height sd-common">
			<div className="sd-details-row-wrapper">
				<div className="sd-details-row-left">
					{/* this title comes as a prop and depending on the routes ("/" or "/invoices") can be "Detalji porudžbine" or "Detalji fakture" */}
					{/* check SideDetailsProps in SideDetails.jsx component */}
					<b>{number.current}</b>
				</div>
				{/* a value that follows the "title", dependant on the route */}
				{location.pathname === "/" && <div className="sd-details-row-right">#{id}</div>}
				{location.pathname === "/invoices" && (
					<div className="sd-details-row-right">#{invoice_number}</div>
				)}
			</div>
			<div className="sd-details-row-wrapper">
				<div className="sd-details-row-left">
					<b>Kupac:</b>
				</div>
				<div className="sd-details-row-right">{buyer}</div>
			</div>
			{/* this one is present only for Orders and not for Invoices */}
			{location.pathname === "/" && (
				<div className="sd-details-row-wrapper">
					<div className="sd-details-row-left">
						<b>Status:</b>
					</div>
					<div className="sd-details-row-right">{status}</div>
				</div>
			)}
			<div className="sd-separator"></div>
			<div className="sd-details-row-wrapper">
				<div className="sd-details-row-left">
					{/* this comes as a prop and depending on the routes ("/" or "/invoices") can be "Datum porudžbine:" or "Datum fakture:" */}
					{/* check SideDetailsProps in SideDetails.jsx component */}
					<b>{date.current}</b>
				</div>
				{/* a value that follows the "title" */}
				<div className="sd-details-row-right">
					{new Date(dateTimeStamp).toLocaleDateString("sr")}
				</div>
			</div>
			{/* this one is present only for Invoices and not for Orders */}
			{location.pathname === "/invoices" && (
				<div className="sd-details-row-wrapper">
					<div className="sd-details-row-left">
						<b>Valuta:</b>
					</div>
					<div className="sd-details-row-right">
						{new Date(payment_due_date).toLocaleDateString("sr")}
					</div>
				</div>
			)}
			<div className="sd-details-row-wrapper">
				<div className="sd-details-row-left">
					<b>Adresa isporuke:</b>
				</div>
				<div className="sd-details-row-right">{address}</div>
			</div>
			<div className="sd-details-row-wrapper">
				<div className="sd-details-row-left">
					{/* this comes as a prop and depending on the routes ("/" or "/invoices") can be "Ukupna vrednost porudžbine:" or ""Ukupan iznos fakture:" */}
					{/* check SideDetailsProps in SideDetails.jsx component */}
					<b>{value.current}</b>
				</div>
				<div className="sd-details-row-right">
					{/* left intentionally to have a quick "how to" if I need localeString formatting and "RSD" currency */}
					{/* {price.toLocaleString("sr", {
							style: "currency",
							currency: "RSD",
							minimumFractionDigits: 2,
						})} */}
					{/* a value that follows the "title" */}
					{/**
					 * INSTRUCTIONS ON HOW TO THINK ABOUT RTK QUERY CACHE & DATA FLOW
					 * To be able to avoid redux store and only keep RTK Query,
					 * we need to use RTK Query to fetch data from API,
					 * since it is in the RTK Query cache automatically, then we just simply use it in this component (or any).
					 * When we need to do some calculations (or change data in any way),
					 * we need to do that in the component (have utils and import them where needed), or use props to pass data to children components.
					 * If a change should reflect in parent component, we need to use callback functions.
					 * If a change should reflect in other components, we need to use signals or context.
					 */}
					{location.pathname === "/" ? contextValue.total_value : amount}
				</div>
			</div>
		</div>
	);
});

export default OrderDetails;
