import { useMemo, useState, useRef, useEffect } from "react";
import logoImage from "./images/logo_CH.svg";
import MainContent from "./components/MainContent";
import Menu from "./components/Menu";
import SideDetails from "./components/SideDetails";
import { SideDetailsContext } from "./contexts_shared/SideDetailsContext";
import { SideDetailsAnimateContext } from "./contexts_shared/SideDetailsAnimateContext";
import { ProductsContext } from './contexts_shared/ProductsContext';
import { DashboardContext } from './contexts_shared/DashboardContext';
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import Login from './components/login/Login';
import { OrderModalProvider } from './components/orders/OrderModalContext';
import TabWrapper from './components/tab-wrapper/TabWrapper';
import { SnackbarNotificationProvider } from './components_shared/snackbar-notification/SnackbarNotificationContext';

const MenuButton = styled(MuiButton)(({ theme }) => ({
	position: "absolute",
	bottom: "90px",
	right: "20px",
	zIndex: 500,
	fontWeight: "bold",
	height: "60px",
	width: "50px",
	borderRadius: "50%",
	backgroundColor: "red",
	"&:hover": {
		backgroundColor: "red",
	},
}));

const App = () => {
	const [addClass, setAddClass] = useState(false);
	const [contextValue, setContextValue] = useState({ // TODO: Resolve state initial value. This is not a good practice.
		id: 1,
		buyer: "John Doe",
		address: "123 Main St",
		dateTimeStamp: "2020-01-01 12:00:00",
		price: 100,
	});
	const providerValue = useMemo(
		() => ({ contextValue, setContextValue }),
		[contextValue, setContextValue],
	);
	const [groupID, setGroupID] = useState(0);
	const [buyerID, setBuyerId] = useState(0);
	const productsContextValue = useMemo(() => ({ groupID, setGroupID, buyerID, setBuyerId }), [groupID, buyerID]);
	const [imagesWithNames, setImagesWithNames] = useState([]);
	const dashboardContextValue = useMemo(() => ({ imagesWithNames, setImagesWithNames }), [imagesWithNames]);
	const sideDetails_wrapper = useRef(null);

	const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem("jwtToken"));
	const [shouldLoadDashboard, setShouldLoadDashboard] = useState(false);

	useEffect(() => {
		const handleStorageChange = () => {
			setIsAuthenticated(localStorage.getItem("jwtToken"));
		};

		window.addEventListener("storage", handleStorageChange);
		return () => window.removeEventListener("storage", handleStorageChange);
	}, []);

	const toggleClass = () => {
		setAddClass(prevAddClass => !prevAddClass);
	};

	function animateSideDetailsIn() {
		sideDetails_wrapper.current.classList.add("animate-side-details");
	}

	function animateSideDetailsOut() {
		sideDetails_wrapper.current.classList.remove("animate-side-details");
	}

	return (
		<>
			{!isAuthenticated && <Login setIsAuthenticated={setIsAuthenticated} />}
			{isAuthenticated && shouldLoadDashboard && (
				<DashboardContext.Provider value={dashboardContextValue}>
					<SnackbarNotificationProvider>
						<TabWrapper />
					</SnackbarNotificationProvider>
				</DashboardContext.Provider>
			)}
			{isAuthenticated && !shouldLoadDashboard && (
				<ProductsContext.Provider value={productsContextValue}>
					<div className="ch-a-container">
						<div className={`ch-side-left ${addClass ? 'added' : ''}`}>
							<img
								src={logoImage}
								alt="logo CH"
								style={{ width: "100%", height: "90px", objectFit: "cover" }}
							/>
							<div className="ch-search" />
							<div className="ch-menu-wrap scroll-handler">
								<SideDetailsAnimateContext.Provider value={{ animateSideDetailsIn, animateSideDetailsOut }}>
									<Menu />
								</SideDetailsAnimateContext.Provider>
							</div>
						</div>

						<SideDetailsAnimateContext.Provider value={{ animateSideDetailsIn, animateSideDetailsOut }}>
							<SideDetailsContext.Provider value={providerValue}>
								<div>
									<MenuButton
										variant="contained"
										className="ch-menu-toggle"
										onClick={toggleClass}
									>
										Meni
									</MenuButton>
								</div>
								<div className="ch-content-right">
									<SnackbarNotificationProvider>
										<OrderModalProvider>
											<MainContent setShouldLoadDashboard={setShouldLoadDashboard} />
										</OrderModalProvider>
									</SnackbarNotificationProvider>
								</div>
								<div ref={sideDetails_wrapper} className="sd-details-wrapper">
									<SideDetails />
								</div>
							</SideDetailsContext.Provider>
						</SideDetailsAnimateContext.Provider>
					</div>
				</ProductsContext.Provider>
			)}
		</>
	);
};

export default App;
