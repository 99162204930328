/** @format */

import { useState } from "react";
import { useForm } from "react-hook-form";
import { Input, Button } from "@mui/material";
import logoImage from "../../images/logo_CH.svg";
import { styled } from "@mui/material/styles";
import { useSubmitLoginFormDataMutation } from "../../api/mainApi";
import "../../App.css";

const StyledForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	width: "400px",
});

const StyledInput = styled(Input)(({ error }) => ({
	margin: "10px 0",
	borderColor: error ? "red" : "inherit",
	"&::placeholder": {
		content: '"*"',
		color: "red",
	},
	"&::before": {
		borderBottom: "1px solid orange",
	},
	"&:hover:not(.Mui-disabled):before": {
		borderBottom: "1px solid #fffc62",
	},
	"&::after": {
		borderBottom: "1px solid #b86f02",
	},
	color: "white",
}));

const StyledButton = styled(Button)({
	margin: "30px 0",
	backgroundColor: "orange",
	"&:hover": {
		backgroundColor: "#e68b02",
	},
});

const ErrorMessage = styled("p")({
	color: "red",
	fontSize: "12px",
	margin: 0,
	paddingTop: "5px",
});

const Login = ({ setIsAuthenticated }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [errorMessage, setErrorMessage] = useState(null);
	const [submitFormData] = useSubmitLoginFormDataMutation();

	const onSubmit = async (data) => {
		try {
			const formData = new FormData();
			formData.append("username", data.username);
			formData.append("password", data.password);

			const response = await submitFormData(formData).unwrap();
			const token = response.token;

			if (token) {
				localStorage.setItem("jwtToken", token);
				setIsAuthenticated(token);
			} else {
				setErrorMessage("Failed to retrieve the token from the response.");
			}
		} catch (error) {
			setErrorMessage(error.data?.error || "An unknown error occurred.");
		}
	};

	return (
		<div className="white-bg-black-text">
			<div
				style={{
					width: "400px",
					marginBottom: "73px",
				}}
			>
				<img
					src={logoImage}
					alt="logo CH"
					style={{ width: "100%", height: "90px", objectFit: "cover" }}
				/>
			</div>
			<StyledForm onSubmit={handleSubmit(onSubmit)}>
				<StyledInput
					placeholder="Korisničko ime *"
					autoFocus
					error={!!errors.username}
					{...register("username", { required: "Korisničko ime je obavezno" })}
					onFocus={() => setErrorMessage(null)}
				/>
				{errors.username && <ErrorMessage>{errors.username.message}</ErrorMessage>}
				<br />
				<StyledInput
					placeholder="Lozinka *"
					type="password"
					error={!!errors.password}
					{...register("password", { required: "Lozinka je obavezna" })}
					onFocus={() => setErrorMessage(null)}
				/>
				{errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
				<br />
				{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
				<StyledButton
					type="submit"
					variant="contained"
					color="primary"
					onClick={() => setErrorMessage(null)}
				>
					Uloguj se
				</StyledButton>
			</StyledForm>
		</div>
	);
};

export default Login;
