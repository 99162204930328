// TODO: This one needs to be refactored for better maintainability (naming mostly)

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const BASE_API_URL = "https://backend-b2b.central-ch.com/scripts/api";

// TODO: memoize this function
const getBuyerId = () => localStorage.getItem('buyerId') || ''; // Fallback to '' empty string for now''

export const mainApi = createApi({
	reducerPath: "mainApi",
	baseQuery: fetchBaseQuery({
		baseUrl: BASE_API_URL, prepareHeaders: (headers) => {
			const token = localStorage.getItem('jwtToken');
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		}
	}),
	endpoints: (builder) => ({
		getOrders: builder.query({
			query: () => "get_orders.php",
			providesTags: ['Order'],
		}),
		updateOrder: builder.mutation({
			query: ({ id, ...patch }) => ({
				url: `update_order.php`,
				method: 'PATCH',
				body: { id, ...patch },
			}),
			invalidatesTags: ['Order'],
			// Optimistically update the cache
			async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					mainApi.util.updateQueryData('getOrders', undefined, (draft) => {
						const order = draft.find((order) => order.id === id);
						if (order) {
							Object.assign(order, patch);
						}
					})
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),

		getFinancialState: builder.query({
			query: () => {
				return "financial-state"
			},
		}),
		getInvoices: builder.query({
			query: () => {
				return "invoices"
			},
		}),
		getPriceList: builder.query({
			query: () => {
				return "price-list"
			},
		}),
		getMenuData: builder.query({
			query: () => "menu_data.php",

			// Another examples of query
			// query: () => fetch("https://backend-b2b.central-ch.com/scripts/api/menu_data.php").then(res => res.json()),
			// query: () => ({ url: "menu_data.php", method: "GET" }), // we can go without GET here
		}),

		/**
		 * * 1. We need to save the token to db when user registers,
		 * * 2. there are old users that reside in the b2b database, we need to register them to the new db through the dashboard... maybe,
		 * * 3. new users need to be registered in the b2b first and then treated as old users !!! DISCUSS WITH MARKO ABOUT THIS
		 * * 4. Basically we take list of users from b2b, add passwords to them, and then add them to the new db,
		 * * 5. we might also add address because we will use it for the delivery when making orders,
		 */
		getProducts: builder.query({
			query: (groupID) => {
				const buyerId = getBuyerId();
				return `products.php?group_id=${groupID}&buyer_id=${buyerId}`;
			},
		}),
		getBuyersNameAndAddress: builder.query({
			query: (buyerId) => `get_buyers_name_and_address.php?buyer_id=${buyerId}`,
		}),
		getGroups: builder.query({
			query: () => "get_groups.php",
		}),
		getProductsForDashboard: builder.query({
			query: (selectedGroupId) => `return_products_for_dashboard.php?selectedGroupId=${selectedGroupId}`,
		}),
		getEditDashboardData: builder.query({
			query: (selectedGroupId) => `get_edit_dashboard_data.php?selectedGroupId=${selectedGroupId}`,
		}),
		submitFormData: builder.mutation({
			query: (formData) => ({
				url: 'dashboard_files_upload.php',
				method: 'POST',
				body: formData,
			}),
		}),
		submitLoginFormData: builder.mutation({
			query: (formData) => ({
				url: 'login.php',
				method: 'POST',
				body: formData,
			}),
		}),
		createOrder: builder.mutation({
			query: (orderData) => ({
				url: 'create_order.php',
				method: 'POST',
				body: orderData,
			}),
			invalidatesTags: ['Order'],
		}),
	}),
});

export const {
	useGetMenuDataQuery,
	useGetProductsQuery,
	useGetBuyersNameAndAddressQuery,
	useGetGroupsQuery,
	useGetProductsForDashboardQuery,
	useGetEditDashboardDataQuery,
	useSubmitFormDataMutation,
	useSubmitLoginFormDataMutation,
	useCreateOrderMutation,
	useGetOrdersQuery,
	useUpdateOrderMutation
} = mainApi;
