import React, { createContext, useState, useContext } from 'react';
import SnackbarNotification from './SnackbarNotification';


const SnackbarNotificationContext = createContext();

export const SnackbarNotificationProvider = ({ children }) => {
	const [snackbarNotification, setSnackbarNotification] = useState({
		open: false,
		message: '',
	});

	const showSnackbarNotification = (message) => {
		setSnackbarNotification({ open: true, message });
	};

	const hideSnackbarNotification = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackbarNotification({ ...snackbarNotification, open: false });
	};

	return (
		<SnackbarNotificationContext.Provider value={{ snackbarNotification, showSnackbarNotification, hideSnackbarNotification }}>
			{children}
			<SnackbarNotification
				open={snackbarNotification.open}
				onClose={hideSnackbarNotification}
				message={snackbarNotification.message}
			/>
		</SnackbarNotificationContext.Provider>
	);
};

export const useSnackbarNotification = () => {
	const context = useContext(SnackbarNotificationContext);
	if (!context) {
		throw new Error('useSnackbarNotification must be used within a SnackbarNotificationProvider');
	}
	return context;
};