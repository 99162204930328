/** @format */

export const formatAddress = (streetName, streetNumber, city) => {
	if (streetNumber === null || streetNumber === "null") {
		return `${streetName}, ${city}`;
	}
	return `${streetName} ${streetNumber}, ${city}`;
};

/**
 * Formats a number as a currency string in Serbian locale.
 *
 * @param {number} value - The numeric value to format.
 * @param {boolean} [shouldShowCurrency=true] - Optional. Whether to include the currency symbol in the formatted string. Default is shown.
 * @example formatCurrency(1000); // Defaults to showing the currency
 * @example formatCurrency(1000, false); // No currency, just formatted number
 * @returns {string} The formatted currency string.
 */
export const formatCurrency = (value, shouldShowCurrency = true) => {
	const options = {
		minimumFractionDigits: 2,
		...(shouldShowCurrency && { style: "currency", currency: "RSD" }),
	};

	return value.toLocaleString("sr", options);
};

export const formatDate = (date) => {
	const parsedDate = new Date(date);
	return parsedDate.toLocaleString("sr", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
	});
};

