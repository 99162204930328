/** @format */

// ! THIS COMPONENT IS ACTUALLY SEARCHING THROUGH THE SUB SUB MENU, SO THE NAME IS NOT CORRECT !

import * as React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { ProductsContext } from "../contexts_shared/ProductsContext";
import eventBus from "../eventBus";

export default function SearchSubmenu({
	searchSubmenu,
	closeSearchSubmenu,
	lines_filteredData,
	selectedItem,
	setSelectedItem,
}) {
	const { setGroupID } = React.useContext(ProductsContext);
	const searchSubMenuWrapper = React.useRef(null);
	// const scroll_handler_wrapper_search = React.useRef(null);

	function animate() {
		searchSubMenuWrapper.current.classList.toggle("animate");
	}

	React.useEffect(() => {
		searchSubmenu && animate();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchSubmenu]);

	React.useEffect(() => {
		// detect overflow of text in line name element and enable ellipsis | set to fire on every data change
		const lineName = document.querySelectorAll(".ellipsis-search");
		const isEllipsisActive = (element) => {
			return element.offsetWidth < element.scrollWidth;
		};
		lineName.forEach((line) => {
			if (isEllipsisActive(line)) {
				line.firstElementChild.style.display = "none";
				line.style.display = "block";
				line.style.overflow = "hidden";
				line.style.textOverflow = "ellipsis";
				line.style.whiteSpace = "nowrap";
				line.style.minHeight = "14px";
			} else {
				line.firstElementChild.style.display = "block";
				line.style.display = "flex";
			}
		});

		// // activates only if scroll is active
		// scroll_handler_wrapper_search.current.style.height = 'unset';
		// if (scroll_handler_wrapper_search.current.scrollHeight > scroll_handler_wrapper_search.current.offsetHeight)
		//     scroll_handler_wrapper_search.current.style.height = `${scroll_handler_wrapper_search.current.offsetHeight - 169}px`;
	}, [lines_filteredData]);

	function stopSearch() {
		eventBus.emit("stopSearch");
	}

	function handleClick(groupId, lineIndex, familyIndex, groupIndex) {
		setGroupID(groupId);
		setSelectedItem({ level: "search", lineIndex, familyIndex, groupIndex });
	}

	return (
		<div
			className="scroll-handler"
			ref={searchSubMenuWrapper}
			style={{
				background: "black",
				zIndex: 4,
				width: "300px",
				height: "-webkit-fill-available",
				marginLeft: "600px",
				padding: "10px 5px",
				position: "absolute",
				top: "165px",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflowY: "auto",
				boxSizing: "border-box",
				transition: "margin-left 0.25s",
			}}
		>
			<List className="ch-list">
				<ListItem
					disablePadding
					className="ch-menu-item"
				>
					<ListItemButton
						onClick={() => {
							animate();
							closeSearchSubmenu();
							stopSearch();
						}}
					>
						<ListItemText primary={"Izlazak iz pretrage"} />
						<ListItemIcon sx={{ color: "#D2D5D1", minWidth: 40 }}>
							<NavigateBeforeIcon
								sx={{
									position: "relative",
									left: "25px",
								}}
							/>
						</ListItemIcon>
					</ListItemButton>
				</ListItem>
			</List>
			{lines_filteredData.map((line, lineIndex) => {
				return (
					<React.Fragment key={lineIndex}>
						{line.families.map((family, familyIndex) => {
							return (
								<React.Fragment key={familyIndex}>
									<div className="ch-menu-splitter ellipsis-search">
										{family.name.toUpperCase()}
										<hr />
									</div>
									{
										<List className="ch-list">
											{
												// ! there is a strong possibility that we can have a slow down here,
												// ! because MUI components are loading in a multiple loop
												// ! solution would be to use a simple elements instead of MUI components
												// ! but then we would have to style them manually
												// ! or we can utilize some kind of lazy loading
												// ? this would be a good place to start:
												// family.groups.map((group, index) => {
												//   return (
												//     <React.Fragment key={index}>
												//       <p style={{color:'green'}}>{group}</p>
												//     </React.Fragment>
												//   )
												// })
												family.groups.map((group, groupIndex) => (
													<React.Fragment key={groupIndex}>
														<ListItem
															disablePadding
															className={`ch-menu-item ${
																selectedItem?.level === "search" &&
																selectedItem?.lineIndex === lineIndex &&
																selectedItem?.familyIndex === familyIndex &&
																selectedItem?.groupIndex === groupIndex
																	? "selected-router-link"
																	: ""
															}`}
														>
															<Link
																className="router-link"
																to={`/products/${group.id}`}
																state={group}
															>
																{/* another way of sending data: https://flaviocopes.com/react-router-data-from-route/ */}
																<ListItemButton
																	onClick={() =>
																		handleClick(group.id, lineIndex, familyIndex, groupIndex)
																	}
																>
																	<ListItemText primary={group.name} />
																	<ListItemIcon
																		sx={{ color: "#D2D5D1", minWidth: 40 }}
																	></ListItemIcon>
																</ListItemButton>
															</Link>
														</ListItem>
													</React.Fragment>
												))
											}
										</List>
									}
								</React.Fragment>
							);
						})}
					</React.Fragment>
				);
			})}
		</div>
	);
}
