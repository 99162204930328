/** @format */

import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";
import { useOrderModal } from "../components/orders/OrderModalContext";

const ShoppingCartButton = () => {
	const { openOrderModal, isCartEnabled, orderData } = useOrderModal();

	const itemCount = orderData.orderItems.filter((item) => item.order_quantity > 0).length;

	return (
		<Box
			style={{
				display: "flex",
				alignItems: "center",
			}}
		>
			<IconButton
				onClick={openOrderModal}
				style={{
					color: isCartEnabled ? "orange" : "grey",
					opacity: isCartEnabled ? 1 : 0.3,
					cursor: isCartEnabled ? "pointer" : "not-allowed",
				}}
				disabled={!isCartEnabled}
			>
				<Badge
					badgeContent={itemCount}
					color="error"
				>
					<ShoppingCartIcon sx={{ fontSize: 40 }} />
				</Badge>
			</IconButton>
		</Box>
	);
};

export default ShoppingCartButton;
