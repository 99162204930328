/** @format */

import React from "react";
import Table from "./DetailsTable";
import Order from "./orders/OrderDetails";
import Button from "@mui/material/Button";
import PDFexportOrders from "../pdf_&_excel/PDFexportOrders";
import PDFexportInvoices from "../pdf_&_excel/PDFexportInvoices";
import ExcelExportOrders from "../pdf_&_excel/ExcelExportOrders";
import ExcelExportInvoices from "../pdf_&_excel/ExcelExportInvoices";
import { SideDetailsAnimateContext } from "../contexts_shared/SideDetailsAnimateContext";
import { SideDetailsContext } from "../contexts_shared/SideDetailsContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
// import { useGetInvoicesQuery } from "../api/mainApi.js"; // TODO: we do not need this one here? Check!!!

const theme = createTheme({
	palette: {
		custom: {
			light: "#FFFFFF",
			main: "#FFFFFF",
			dark: "#000000",
			contrastText: "rgba(0, 0, 0, 0.88)",
		},
	},
});

const SideDetails = React.memo((title) => {
	const { animateSideDetailsOut } = React.useContext(SideDetailsAnimateContext);
	const { contextValue } = React.useContext(SideDetailsContext);
	const { order } = contextValue;
	const pdfFileName = () => {
		const date = new Date(contextValue.dateTimeStamp).toLocaleDateString("sr").slice(0, -1);
		const fileName = `${contextValue.buyer} - ${date} - ${contextValue.id}.pdf`;
		return fileName;
	};
	const titleRef = React.useRef(""); // Create a mutable ref for title
	const numberRef = React.useRef("");
	const dateRef = React.useRef("");
	const valueRef = React.useRef("");

	// Sending titles OrderDetails component depending on the route
	const SideDetailsProps = {
		title: {
			"/": "Detalji porudžbine",
			"/invoices": "Detalji fakture",
		},
		number: {
			"/": "Porudžbina br:",
			"/invoices": "Faktura br:",
		},
		date: {
			"/": "Datum porudžbine:",
			"/invoices": "Datum fakture:",
		},
		value: {
			"/": "Ukupna vrednost porudžbine:",
			"/invoices": "Ukupan iznos fakture:",
		},
	};
	const location = useLocation();

	React.useEffect(() => {
		titleRef.current = SideDetailsProps.title[location.pathname];
		numberRef.current = SideDetailsProps.number[location.pathname];
		dateRef.current = SideDetailsProps.date[location.pathname];
		valueRef.current = SideDetailsProps.value[location.pathname];
	}, [
		location,
		SideDetailsProps.title,
		SideDetailsProps.number,
		SideDetailsProps.date,
		SideDetailsProps.value,
	]);

	return (
		<>
			<div className="sd-header sd-common">
				<h2>{titleRef.current}</h2>
				<ThemeProvider theme={theme}>
					<Button
						variant="outlined"
						onClick={animateSideDetailsOut}
						color="custom"
					>
						Izlazak iz detalja
					</Button>
				</ThemeProvider>
			</div>
			{/* these are names for the fields, not values */}
			<Order
				number={numberRef}
				date={dateRef}
				value={valueRef}
			/>
			<div className="sd-products sd-height sd-common">
				<div className="pdf-exl-wrap">
					<h3>Pregled po artiklima</h3>
					<div className="pdf-exl-buttons-wrap">
						<PDFDownloadLink
							document={
								location.pathname === "/invoices" ? (
									<PDFexportInvoices data={order} />
								) : (
									<PDFexportOrders order={order} />
								)
							}
							fileName={pdfFileName()}
						>
							{({ loading }) =>
								loading ? (
									"Spremam pdf fajl..."
								) : (
									<Button
										className="pdf-btn"
										variant="contained"
									>
										.pdf
									</Button>
								)
							}
						</PDFDownloadLink>
						{location.pathname === "/invoices" ? <ExcelExportInvoices /> : <ExcelExportOrders />}
					</div>
				</div>
				<Table order={order} />
			</div>
		</>
	);
});

export default SideDetails;
