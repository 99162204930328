/** @format */

import React, { useState } from "react";
import {
	Select,
	MenuItem,
	TextField,
	InputLabel,
	FormControl,
	Card,
	CardContent,
	CardHeader,
	Button,
	IconButton,
	Typography,
	Grid,
	Box,
	Divider,
	InputAdornment,
	Paper,
} from "@mui/material";
import { Visibility, VisibilityOff, Link as LinkIcon } from "@mui/icons-material";

// Mock database data
const mockUsers = [
	{
		buyerId: "B001",
		companyName: "Tech Solutions Inc",
		address: "123 Tech Street, Silicon Valley, CA",
		email: "contact@techsolutions.com",
	},
	{
		buyerId: "B002",
		companyName: "Global Innovations Ltd",
		address: "456 Innovation Ave, New York, NY",
		email: "info@globalinnovations.com",
	},
];

// Mock admins data
const mockAdmins = [
	{
		adminId: "A001",
		firstName: "John",
		lastName: "Doe",
		email: "john@centralh.com",
		role: "admin",
	},
	{
		adminId: "A002",
		firstName: "Jane",
		lastName: "Smith",
		email: "jane@centralh.com",
		role: "superadmin",
	},
];

const UserManagementSystem = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedAdmin, setSelectedAdmin] = useState(null);
	const [formMode, setFormMode] = useState("user");
	const [userFormData, setUserFormData] = useState({
		firstName: "",
		lastName: "",
		role: "buyer",
		companyName: "",
		buyerId: "",
		address: "",
		username: "",
		password: "",
		email: "",
	});
	const [adminFormData, setAdminFormData] = useState({
		firstName: "",
		lastName: "",
		role: "admin",
		companyName: "Central H",
		buyerId: "",
		address: "",
		username: "",
		password: "",
		email: "",
	});

	const [connections, setConnections] = useState([]);

	const handleUserSelect = (event) => {
		const buyerId = event.target.value;
		const user = mockUsers.find((u) => u.buyerId === buyerId);
		if (user) {
			setSelectedUser(user);
			setUserFormData({
				...userFormData,
				companyName: user.companyName,
				buyerId: user.buyerId,
				address: user.address,
				email: user.email,
			});
		}
	};

	const handleInputChange = (formType, field) => (event) => {
		const value = event.target.value;
		if (formType === "user") {
			setUserFormData((prev) => ({
				...prev,
				[field]: value,
			}));
		} else {
			setAdminFormData((prev) => ({
				...prev,
				[field]: value,
			}));
		}
	};

	const handleConnection = () => {
		if (selectedUser && selectedAdmin) {
			const newConnection = {
				adminId: selectedAdmin.adminId,
				userId: selectedUser.buyerId,
			};

			const connectionExists = connections.some(
				(conn) => conn.adminId === newConnection.adminId && conn.userId === newConnection.userId,
			);

			if (connectionExists) {
				alert("This connection already exists!");
				return;
			}

			setConnections((prev) => [...prev, newConnection]);
		}
	};

	const renderUserForm = (formType, data) => {
		const isAdminForm = formType === "admin";

		return (
			<Card>
				<CardHeader title={isAdminForm ? "Admin Details" : "User Details"} />
				<CardContent>
					<Box
						component="form"
						sx={{ "& .MuiTextField-root": { mb: 2 } }}
					>
						<Grid
							container
							spacing={2}
						>
							{/* Company Information */}
							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label="Company Name"
									value={isAdminForm ? "Central H" : data.companyName}
									InputProps={{ readOnly: true }}
									size="small"
								/>
							</Grid>
							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label="Buyer ID"
									value={data.buyerId}
									onChange={handleInputChange(formType, "buyerId")}
									InputProps={{ readOnly: !isAdminForm }}
									size="small"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									label="Address"
									value={data.address}
									onChange={handleInputChange(formType, "address")}
									size="small"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<TextField
									fullWidth
									type="email"
									label="Email"
									value={data.email}
									onChange={handleInputChange(formType, "email")}
									size="small"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<Divider sx={{ my: 2 }}>
									<Typography
										variant="body2"
										color="textSecondary"
									>
										Login Credentials
									</Typography>
								</Divider>
							</Grid>

							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label="Username"
									value={data.username}
									onChange={handleInputChange(formType, "username")}
									size="small"
								/>
							</Grid>
							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									type={showPassword ? "text" : "password"}
									label="Password"
									value={data.password}
									onChange={handleInputChange(formType, "password")}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => setShowPassword(!showPassword)}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
									size="small"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<Divider sx={{ my: 2 }}>
									<Typography
										variant="body2"
										color="textSecondary"
									>
										Personal Information
									</Typography>
								</Divider>
							</Grid>

							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label="First Name"
									value={data.firstName}
									onChange={handleInputChange(formType, "firstName")}
									size="small"
								/>
							</Grid>
							<Grid
								item
								xs={6}
							>
								<TextField
									fullWidth
									label="Last Name"
									value={data.lastName}
									onChange={handleInputChange(formType, "lastName")}
									size="small"
								/>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<FormControl fullWidth>
									<InputLabel>Role</InputLabel>
									<Select
										value={data.role}
										onChange={handleInputChange(formType, "role")}
										label="Role"
										disabled={!isAdminForm}
										size="small"
									>
										{isAdminForm ? (
											[
												<MenuItem
													key="admin"
													value="admin"
												>
													Admin
												</MenuItem>,
												<MenuItem
													key="superadmin"
													value="superadmin"
												>
													Super Admin
												</MenuItem>,
											]
										) : (
											<MenuItem value="buyer">Buyer</MenuItem>
										)}
									</Select>
								</FormControl>
							</Grid>

							<Grid
								item
								xs={12}
							>
								<Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
									<Button
										variant="outlined"
										onClick={() => {
											if (formType === "admin") {
												setAdminFormData({
													firstName: "",
													lastName: "",
													role: "admin",
													companyName: "Central H",
													buyerId: "",
													address: "",
													username: "",
													password: "",
													email: "",
												});
											} else {
												setUserFormData((prev) => ({
													...prev,
													firstName: "",
													lastName: "",
													username: "",
													password: "",
												}));
											}
										}}
									>
										Clear
									</Button>
									<Button
										variant="contained"
										onClick={() => {
											const formToSave = formType === "admin" ? adminFormData : userFormData;
											console.log(`Saving ${formType} data:`, formToSave);
											alert(
												`${
													formType.charAt(0).toUpperCase() + formType.slice(1)
												} data saved successfully!`,
											);
										}}
									>
										Save {formType.charAt(0).toUpperCase() + formType.slice(1)}
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</CardContent>
			</Card>
		);
	};

	const renderConnectionsPanel = () => (
		<Card sx={{ mt: 2 }}>
			<CardHeader title="User-Admin Connections" />
			<CardContent>
				<Box sx={{ "& > *": { mb: 2 } }}>
					{connections.map((connection, index) => (
						<Paper
							key={index}
							variant="outlined"
							sx={{
								p: 2,
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography>
								Admin: {connection.adminId} ↔ User: {connection.userId}
							</Typography>
							<Button
								variant="text"
								size="small"
								onClick={() => {
									setConnections(connections.filter((_, i) => i !== index));
								}}
							>
								Remove
							</Button>
						</Paper>
					))}
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<Button
							variant="outlined"
							startIcon={<LinkIcon />}
							onClick={handleConnection}
							disabled={!selectedUser || !selectedAdmin}
						>
							Connect Selected User and Admin
						</Button>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						pt: 2,
						borderTop: 1,
						borderColor: "divider",
					}}
				>
					<Button
						variant="contained"
						onClick={() => {
							console.log("Saving connections:", connections);
							alert("Connections saved successfully!");
						}}
						disabled={connections.length === 0}
					>
						Save Connection{connections.length !== 1 ? "s" : ""}
					</Button>
				</Box>
			</CardContent>
		</Card>
	);

	return (
		<Box sx={{ p: 3, height: "84vh" }}>
			{/* Mode Selection */}
			<Box sx={{ mb: 3, display: "flex", gap: 2 }}>
				<Button
					variant={formMode === "user" ? "contained" : "outlined"}
					onClick={() => setFormMode("user")}
				>
					Manage Users
				</Button>
				<Button
					variant={formMode === "admin" ? "contained" : "outlined"}
					onClick={() => setFormMode("admin")}
				>
					Manage Admins
				</Button>
			</Box>

			<Grid
				container
				spacing={3}
			>
				{/* Left side - Forms */}
				<Grid
					item
					xs={6}
				>
					{formMode === "user" ? (
						<>
							<Card sx={{ mb: 2 }}>
								<CardHeader title="Select User" />
								<CardContent>
									<FormControl fullWidth>
										<InputLabel size="small">Select a company</InputLabel>
										<Select
											value={selectedUser?.buyerId || ""}
											onChange={handleUserSelect}
											label="Select a company"
											size="small"
										>
											{mockUsers.map((user) => (
												<MenuItem
													key={user.buyerId}
													value={user.buyerId}
												>
													{user.companyName}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</CardContent>
							</Card>
							{renderUserForm("user", userFormData)}
						</>
					) : (
						renderUserForm("admin", adminFormData)
					)}
				</Grid>

				{/* Right side - Connections */}
				<Grid
					item
					xs={6}
				>
					<Card>
						<CardHeader title="Admin Selection" />
						<CardContent>
							<FormControl fullWidth>
								<InputLabel size="small">Select an admin</InputLabel>
								<Select
									value={selectedAdmin?.adminId || ""}
									onChange={(event) => {
										const admin = mockAdmins.find((a) => a.adminId === event.target.value);
										setSelectedAdmin(admin);
									}}
									label="Select an admin"
									size="small"
								>
									{mockAdmins.map((admin) => (
										<MenuItem
											key={admin.adminId}
											value={admin.adminId}
										>
											{`${admin.firstName} ${admin.lastName} (${admin.role})`}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</CardContent>
					</Card>

					{renderConnectionsPanel()}
				</Grid>
			</Grid>
		</Box>
	);
};

export default UserManagementSystem;
