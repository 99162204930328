/** @format */

import * as React from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { getRole, getDisplayname } from "../utils/auth";
import OrdersTable from "./orders/OrdersTable";
import InvoicesTable from "./InvoicesTable";
import FinancialStateTable from "./FinancialStateTable";
import PriceList from "./PriceList";
import ProductsPage from "./ProductsPage";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ShoppingCartButton from "./ShoppingCartButton";

const RightPartHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const StyledUsername = styled.p`
	margin-right: 10px;
`;

const MainContent = ({ setShouldLoadDashboard }) => {
	const username = getDisplayname();
	const isAdmin = getRole() === "admin";

	const logout = () => {
		localStorage.removeItem("jwtToken");
		window.location.reload();
	};
	const location = useLocation();
	const isProductPage = location.pathname.startsWith("/products/");

	return (
		<>
			<div className="ch-header">
				<StyledUsername>
					<span>Ulogovani korisnik: {username}</span>
					{isAdmin && (
						<Button
							variant="contained"
							color="primary"
							onClick={() => setShouldLoadDashboard(true)}
							sx={{
								background: "orange",
								"&:hover": {
									backgroundColor: "#e68b02",
									boxShadow: "none",
								},
								boxShadow: "none",
								marginLeft: "35px",
								height: "40px",
							}}
						>
							Dashboard
						</Button>
					)}
				</StyledUsername>

				<RightPartHeader>
					{/* TODO: This should be a component which will handle notifications */}
					{/* TODO: Add a red circle with number showing amount of new notifications */}
					<IconButton
						// onClick={} // TODO: Add a function to handle notifications
						style={{
							color: "orange",
						}}
					>
						<NotificationsIcon sx={{ fontSize: 40 }} />
					</IconButton>
					{/* TODO: What should we do with the items that do not have the price in the table? */}
					{isProductPage && !isAdmin && <ShoppingCartButton />}
					<Button
						variant="contained"
						color="primary"
						onClick={logout}
						sx={{
							background: "orange",
							"&:hover": {
								backgroundColor: "#e68b02",
								boxShadow: "none",
							},
							boxShadow: "none",
							marginLeft: "20px",
							height: "40px",
						}}
					>
						Izloguj se
					</Button>
				</RightPartHeader>
			</div>
			<div className="ch-table-wrapper scroll-handler">
				<Routes>
					<Route
						path="/"
						element={<OrdersTable />}
					/>
					<Route
						path="/invoices"
						element={<InvoicesTable />}
					/>
					<Route
						path="/finances"
						element={<FinancialStateTable />}
					/>
					<Route
						path="/prices"
						element={<PriceList />}
					/>
					<Route
						path="/products/:id"
						element={<ProductsPage />}
					/>
					<Route
						path="*"
						element={<p style={{ color: "black", textAlign: "center" }}>404 NOT FOUND</p>}
					/>
				</Routes>
			</div>
		</>
	);
};

export default MainContent;
