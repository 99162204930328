/** @format */

import React, { useState } from "react";

const Droppable = ({ children, onDrop, isImageDropped }) => {
	const [isDragOver, setIsDragOver] = useState(false);

	const handleDragOver = (e) => {
		e.preventDefault();
		setIsDragOver(true);
	};

	const handleDragLeave = () => {
		setIsDragOver(false);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		const id = e.dataTransfer.getData("text");
		onDrop(id);
		setIsDragOver(false);
	};

	return (
		// TODO: Styles should be moved to a styled component
		<div
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			style={{
				border: isDragOver
					? "2px solid red"
					: isImageDropped
					? "2px solid green"
					: "2px solid orange",
				borderRadius: "7px",
				padding: "10px",
				marginBottom: "10px",
				minHeight: "50px",
			}}
		>
			{children}
		</div>
	);
};

export default Droppable;
