export const statusTranslations = {
	pending: "Na čekanju",
	processing: "U pripremi",
	accepted: "Prihvaćeno",
	modified: "Prihvaćeno uz izmene",
	declined: "Odbijeno"
};

export const getStatusColor = (status) => {
	switch (status) {
		case 'pending': return 'default';
		case 'processing': return 'primary';
		case 'accepted': return 'success';
		case 'modified': return 'warning';
		case 'declined': return 'error';
		default: return 'default';
	}
};

export const getSerbianStatus = (englishStatus) => {
	return statusTranslations[englishStatus] || englishStatus;
};

export const getEnglishStatus = (serbianStatus) => {
	const entry = Object.entries(statusTranslations).find(([_, value]) => value === serbianStatus);
	return entry ? entry[0] : serbianStatus;
};
