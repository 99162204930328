/** @format */

import { useState, useEffect } from "react";
// TODO: Read the comment below
/**
 * Currently we are querying the address by buyerId,
 * and we are also querying buyer's name from b2b database.
 * This should change to querying from local database,
 * because we will put that data there during registration.
 */
import { useGetBuyersNameAndAddressQuery } from "../../api/mainApi";
import { useCreateOrderMutation } from "../../api/mainApi";
import { useSnackbarNotification } from "../../components_shared/snackbar-notification/SnackbarNotificationContext";
import { getBuyerId } from "../../utils/auth";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	borderRadius: 2,
	transform: "translate(-50%, -50%)",
	width: "65%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	maxHeight: "70vh",
	overflow: "auto",
};

const serbianCurrencyFormat = (num) => {
	return new Intl.NumberFormat("sr-RS", {
		// When we need to display currency, we can use the following options
		// style: "currency",
		// currency: "RSD",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(num);
};

const validateAddress = (address) => {
	if (!address.trim()) {
		return "Adresa je obavezna.";
	}
	if (address.trim().length < 10) {
		return "Adresa mora imati najmanje 10 karaktera.";
	}
	if (address.trim().length > 200) {
		return "Adresa ne sme biti duža od 200 karaktera.";
	}
	// Character set restriction
	const allowedPattern = /^[a-zA-ZšđčćžŠĐČĆŽ0-9\s.,#-]+$/;
	if (!allowedPattern.test(address)) {
		return "Adresa sadrži nedozvoljene karaktere.";
	}
	return ""; // No error
};

const OrderModal = ({ open, handleClose, handleCancelOrder, orderItems, totalWithoutPDV }) => {
	const [buyerName, setBuyerName] = useState("");
	const [shippingAddress, setShippingAddress] = useState("");
	const [shippingAddressError, setShippingAddressError] = useState("");
	const buyerId = getBuyerId();

	const { showSnackbarNotification } = useSnackbarNotification();

	// TODO: Add title to the modal, like "Moja porudžbina"
	// TODO: Add a button to delete items from the order
	// TODO: Add a button to edit the order (change quantity, etc.)

	const {
		data: buyersNameAndAddress,
		error: buyerDataError,
		isLoading: isBuyerDataLoading,
	} = useGetBuyersNameAndAddressQuery(buyerId);

	useEffect(() => {
		if (buyersNameAndAddress) {
			setShippingAddress(buyersNameAndAddress.address || "");
			setBuyerName(buyersNameAndAddress.name || "");
		}
	}, [buyersNameAndAddress]);

	useEffect(() => {
		if (buyerDataError) {
			setShippingAddressError("Failed to load buyer information. Please enter manually.");
		}
	}, [buyerDataError]);

	const [createOrder, { isLoading: isCreatingOrder }] = useCreateOrderMutation();

	const handleConfirmOrder = async () => {
		const addressError = validateAddress(shippingAddress);
		if (addressError) {
			setShippingAddressError(addressError);
			return;
		}

		const orderData = {
			buyer_id: buyerId,
			buyer_name: buyerName,
			buyer_address: shippingAddress,
			total_value: totalWithoutPDV,
			items: orderItems.map((item) => ({
				id: item.id,
				product_name: item.product_name,
				vp_price: parseFloat(item.vp_price),
				rabat: item.rabat,
				net_price: parseFloat(item.net_price),
				order_quantity: parseInt(item.order_quantity),
				jm: item.jm, // jedinica mere
			})),
		};

		try {
			const response = await createOrder(orderData).unwrap();
			handleClose();
			showSnackbarNotification(
				`Porudžbina je uspešno kreirana. ${response.email_status || "PHP status error."}`,
			);
		} catch (error) {
			console.error("Failed to create order:", error);
			showSnackbarNotification(
				`Došlo je do greške prilikom kreiranja porudžbine: ${
					error.data?.error || error.message
				}. Email status: ${error.data?.email_status || "PHP status error."}`,
			);
		}
	};

	const handleAddressChange = (e) => {
		const newAddress = e.target.value;
		setShippingAddress(newAddress);

		// Perform validation
		const error = validateAddress(newAddress);
		setShippingAddressError(error);
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="order-modal-title"
			aria-describedby="order-modal-description"
		>
			<Box sx={style}>
				<Typography
					id="order-modal-title"
					variant="h6"
					component="h2"
				>
					Your Order
				</Typography>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>ID broj</TableCell>
							<TableCell align="right">Naziv proizvoda</TableCell>
							<TableCell align="right">Količina</TableCell>
							<TableCell align="right">Neto cena</TableCell>
							<TableCell align="right">Vrednost</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{orderItems.map((item) => {
							const name = item.product_name;
							const quantity = parseInt(item.order_quantity) || 0;
							const price = parseFloat(item.net_price) || 0;
							const total = quantity * price;
							return (
								<TableRow key={item.id}>
									<TableCell
										component="th"
										scope="row"
									>
										{item.id}
									</TableCell>
									<TableCell align="right">{name}</TableCell>
									<TableCell align="right">{quantity}</TableCell>
									<TableCell align="right">{serbianCurrencyFormat(price)}</TableCell>
									<TableCell align="right">{serbianCurrencyFormat(total)}</TableCell>
								</TableRow>
							);
						})}
						{/* Total without PDV */}
						<TableRow>
							<TableCell
								colSpan={4}
								align="right"
							>
								Ukupno bez PDV-a:
							</TableCell>
							<TableCell align="right">{serbianCurrencyFormat(totalWithoutPDV)}</TableCell>
						</TableRow>
						{/* PDV only */}
						<TableRow>
							<TableCell
								colSpan={4}
								align="right"
							>
								PDV (20%):
							</TableCell>
							<TableCell align="right">{serbianCurrencyFormat(totalWithoutPDV * 0.2)}</TableCell>
						</TableRow>
						{/* Total with PDV */}
						<TableRow>
							<TableCell
								colSpan={4}
								align="right"
							>
								Ukupno sa PDV-om:
							</TableCell>
							<TableCell align="right">{serbianCurrencyFormat(totalWithoutPDV * 1.2)}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				{/* Street names capitalization handling */}
				{/* https://chatgpt.com/share/66efd51c-9680-8003-a31e-c1d4caa1338a */}
				<TextField
					fullWidth
					label="Adresa za isporuku"
					value={shippingAddress}
					onChange={handleAddressChange}
					onBlur={() => setShippingAddressError(validateAddress(shippingAddress))}
					error={!!shippingAddressError}
					// TODO: I actually do not see the error message API is returning. Instead, I see FE message To reproduce, set some invalid ID in localStorage
					helperText={
						shippingAddressError ||
						(isBuyerDataLoading ? "Loading buyer information..." : "") ||
						(buyerDataError ? "Failed to load buyer information. Please enter manually." : "")
					}
					multiline
					rows={2}
					margin="normal"
				/>
				{/* TODO: Canceling the order should set Porudzbina column's data all to 0 */}
				<Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
					<Button
						onClick={() => {
							handleCancelOrder();
							handleClose();
						}}
						sx={{ mr: 1 }}
					>
						Obriši porudžbinu
					</Button>
					<Button
						onClick={() => {
							handleConfirmOrder();
							handleCancelOrder();
						}}
						variant="contained"
						sx={{ backgroundColor: "orange", "&:hover": { backgroundColor: "darkorange" } }}
					>
						{isCreatingOrder ? "Kreira se..." : "Završi porudžbinu"}
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default OrderModal;
