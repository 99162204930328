import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const MOCK_API_URL = "https://my-json-server.typicode.com/codepanov/b2b_mock/";

export const mainApi = createApi({
	reducerPath: "mainApi",
	baseQuery: fetchBaseQuery({
		baseUrl: MOCK_API_URL,
		prepareHeaders: (headers) => {
			const token = localStorage.getItem('jwtToken');
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		// getOrders: builder.query({
		// 	query: () => {
		// 		return "orders"
		// 	},
		// }),
		getFinancialState: builder.query({
			query: () => {
				return "financial-state"
			},
			// transformResponse: () => []
		}),
		getInvoices: builder.query({
			query: () => {
				return "invoices"
			},
			// transformResponse: () => []
		}),
		getPriceList: builder.query({
			query: () => {
				return "price-list"
			},
			// transformResponse: () => []
		}),
	}),
});

export const { useGetFinancialStateQuery, useGetInvoicesQuery, useGetPriceListQuery } = mainApi;